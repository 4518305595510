var $class="se2--header-only-tabs",$name="HeaderOnlyTabs",$path="app/components/HeaderOnlyTabs/index.js",$this={$class,$name,$path,};import './partials/skipNavigation';
import shell from 'app/modules/shell';

const mobileMenuButtonSelector = '.mobile-menu-btn';
const expandedMenuClassName = 'expanded';
const currentPageClassName = 'current-page';
const headerLinksSelector = 'nav .nav-menu-item a';
const ariaLabelAttribute = 'aria-label';

const onMenuButtonClick = (element, menuButton) => {
  const menuButtonAriaLabelOpen = menuButton.getAttribute(ariaLabelAttribute);
  const menuButtonAriaLabelClose = menuButton.getAttribute(
    'data-aria-label-close',
  );
  const isMenuOpened = element.classList.contains(expandedMenuClassName);

  if (isMenuOpened) {
    element.classList.remove(expandedMenuClassName);
    menuButton.setAttribute(ariaLabelAttribute, menuButtonAriaLabelOpen);
    menuButton.setAttribute('aria-expanded', false);
  } else {
    element.classList.add(expandedMenuClassName);
    menuButton.setAttribute(ariaLabelAttribute, menuButtonAriaLabelClose);
    menuButton.setAttribute('aria-expanded', true);
  }
};

const clearUrl = (url) => {
  let modifiedUrl = url.replace('index.jsp', '');
  if (modifiedUrl.endsWith('/')) {
    modifiedUrl = modifiedUrl.slice(0, -1);
  }
  return modifiedUrl;
};

const markCurrentPageMenuItem = (element) => {
  Array.from(element.querySelectorAll(`.${currentPageClassName}`))
    .forEach((e) => e.classList.remove(currentPageClassName));
  const href = clearUrl(window.location.href);
  const headerLinks = element.querySelectorAll(headerLinksSelector);
  const currentPageLink = Array.from(headerLinks)
    .find((e) => href.endsWith(clearUrl(e.href)));
  currentPageLink?.classList.add(currentPageClassName);
};

const getOnscrollHandler = () => {
  let bodyTopPosition = 0;

  return (event, header) => {
    const { scrollTop } = event.target.scrollingElement;
    const { offsetHeight, style } = header;
    const newBodyTopPosition = document.body.getBoundingClientRect().top;
    const isScrollingUp = newBodyTopPosition > bodyTopPosition;
    bodyTopPosition = newBodyTopPosition;
    if (isScrollingUp) {
      style['inset-block-start'] = '0';
    } else if (scrollTop > offsetHeight) {
      style['inset-block-start'] = `-${offsetHeight}px`;
    }
  };
};

export default shell.registerComponent($this, ({ mount, addEventListener }) => {
  mount((element) => {
    markCurrentPageMenuItem(element);
    const menuButton = element.querySelector(mobileMenuButtonSelector);
    addEventListener(menuButton, 'click', () => onMenuButtonClick(element, menuButton));
    const onScrollHandler = getOnscrollHandler();
    addEventListener(window, 'scroll', (event) => onScrollHandler(event, element));
  });
});
